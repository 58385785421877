<template>
  <div v-if="isLoading" class="landing-container">
    <VueText
      style="margin: 30px 0 15px 15px;display: flex;
    justify-content: center;"
      sizeLevel="8"
      weightLevel="2"
    >
      {{ landingHeader }}</VueText
    >
    <div class="landing-list">
      <div
        v-for="(item, index) in marketingVideoUrlList"
        :id="`landing-video-${index}`"
        :key="index"
        class="landing-item"
      >
        <iframe :src="`${item.embedVideo}`" />
        <VueText style="margin-top: 5px;" sizeLevel="5" weightLevel="3">{{ item.name }}</VueText>
        <VueText style="margin-top: 5px;" sizeLevel="3" color="grey-30">{{
          item.description
        }}</VueText>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Common } from '@/services/Api/index';
import StaticContent from '@/services/Api/staticContents';
import vimeoUtils from '@/mixins/vimeoUtils.js';

export default {
  name: 'VideoSeries',
  components: { VueText },
  mixins: [vimeoUtils],
  data() {
    return {
      marketingVideoUrlList: [],
      landingHeader: '',
      isLoading: false,
    };
  },
  mounted() {
    this.getMarketingPageTitle();
    this.getMarketingVideoSeries();
    this.isLoading = true;
  },
  methods: {
    getMarketingPageTitle() {
      StaticContent.getStaticContent('MarketingPageTitle').then(res => {
        if (!res.data.Data) return;
        this.landingHeader = res?.data?.Data?.content;
      });
    },
    getMarketingVideoSeries() {
      Common.getMarketingVideoSeries().then(res => {
        const {
          Data: { marketingVideoUrlList },
        } = res.data;

        this.marketingVideoUrlList = this.getVideoThumbs(marketingVideoUrlList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &-item {
    width: 100%;
    max-width: 24%;
    margin: 5px;
    padding: 2% 1%;
    background-color: rgb(249, 249, 249);
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

iframe {
  width: 100% !important;
}
</style>
